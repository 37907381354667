var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "always" } },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.title,
        },
        on: {
          "head-save": function ($event) {
            return _vm.submitForm()
          },
          "head-back": function ($event) {
            return _vm.showCard()
          },
          "head-ltx": function ($event) {
            return _vm.handleCheckConnection()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "body-wrapper" },
        [
          _c(
            "el-collapse",
            {
              staticStyle: { margintop: "10px" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "i",
                      {
                        staticClass: "avue-group__title avue-group__header",
                        staticStyle: {
                          color: "#999999 !important",
                          "font-style": "normal",
                          padding: "0 10px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-document",
                          staticStyle: {
                            "font-size": "20px",
                            padding: "0 10px",
                          },
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("cip.dc.datasource.field.sjyxx")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.status"
                                    ),
                                    prop: "status",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "status", $$v)
                                        },
                                        expression: "form.status",
                                      },
                                    },
                                    _vm._l(_vm.statusOptions, function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.id,
                                          attrs: { label: dict.itemText },
                                        },
                                        [_vm._v(_vm._s(dict.itemValue))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.datasourceType"
                                    ),
                                    prop: "dbType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.form.dbType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dbType", $$v)
                                        },
                                        expression: "form.dbType",
                                      },
                                    },
                                    _vm._l(_vm.dbTypeOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.itemValue,
                                          value: item.itemText,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.name"
                                    ),
                                    prop: "sourceName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t("cip.dc.datasource.field.name"),
                                    },
                                    model: {
                                      value: _vm.form.sourceName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "sourceName", $$v)
                                      },
                                      expression: "form.sourceName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.offlineDComission.modelState"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.modelState,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "modelState", $$v)
                                        },
                                        expression: "form.modelState",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cip.dc.offlineDComission.is"
                                            )
                                          ) + "   "
                                        ),
                                      ]),
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cip.dc.offlineDComission.no"
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.remark"
                                    ),
                                    prop: "remark",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.datasource.field.remark"
                                        ),
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { name: "2" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "i",
                      {
                        staticClass: "avue-group__title avue-group__header",
                        staticStyle: {
                          color: "#999999 !important",
                          "font-style": "normal",
                          padding: "0 10px",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-document",
                          staticStyle: {
                            "font-size": "20px",
                            padding: "0 10px",
                          },
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("cip.dc.datasource.field.ljxx")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form2",
                      attrs: {
                        model: _vm.form2,
                        rules: _vm.rules2,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.host"
                                    ),
                                    prop: "host",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t("cip.dc.datasource.field.host"),
                                    },
                                    model: {
                                      value: _vm.form2.host,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form2, "host", $$v)
                                      },
                                      expression: "form2.host",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.port"
                                    ),
                                    prop: "port",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t("cip.dc.datasource.field.port"),
                                    },
                                    model: {
                                      value: _vm.form2.port,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form2, "port", $$v)
                                      },
                                      expression: "form2.port",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _vm.form.dbType === "3" || _vm.form.dbType === "4"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.datasource.field.sid"
                                        ),
                                        prop: "sid",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t(
                                              "cip.cmn.rule.inputWarning"
                                            ) +
                                            _vm.$t(
                                              "cip.dc.datasource.field.sid"
                                            ),
                                        },
                                        model: {
                                          value: _vm.form2.sid,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form2, "sid", $$v)
                                          },
                                          expression: "form2.sid",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.dbType !== "3" && _vm.form.dbType !== "4"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.datasource.field.dbName"
                                        ),
                                        prop: "dbName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t(
                                              "cip.cmn.rule.inputWarning"
                                            ) +
                                            _vm.$t(
                                              "cip.dc.datasource.field.dbName"
                                            ),
                                        },
                                        model: {
                                          value: _vm.form2.dbName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form2, "dbName", $$v)
                                          },
                                          expression: "form2.dbName",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.username"
                                    ),
                                    prop: "username",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.datasource.field.username"
                                        ),
                                    },
                                    model: {
                                      value: _vm.form2.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form2, "username", $$v)
                                      },
                                      expression: "form2.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.datasource.field.password"
                                    ),
                                    prop: "password",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.datasource.field.password"
                                        ),
                                    },
                                    model: {
                                      value: _vm.form2.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form2, "password", $$v)
                                      },
                                      expression: "form2.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }