<template>
  <el-card class="box-card" shadow="always">
     <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="title"
      @head-ysj="handleSync()"
      @head-back="showCard()"
    ></head-layout>
    <!-- <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button-group style="float: right;">
        <el-button v-hasPerm="['metadata:datasource:sync']" v-if="form.isSync === '0'" size="mini" icon="el-icon-coin" round @click="handleSync">{{$t("cip.dc.datasource.field.ysjtb")}}</el-button>

        <el-button v-hasPerm="['metadata:datasource:connect']" size="mini" icon="el-icon-coin" round @click="handleCheckConnection">{{$t("cip.dc.datasource.field.ltxcs")}}</el-button>
        <el-button size="mini" icon="el-icon-back" round @click="showCard">返回</el-button>
      </el-button-group>
    </div> -->
    <div class="body-wrapper">
      <!-- <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="$t('cip.dc.datasource.field.sjyxx')" />
        <el-step :title="$t('cip.dc.datasource.field.ljxx')" />
      </el-steps> -->


        <el-collapse v-model="activeName" style="margintop: 10px">
      <el-collapse-item name="1">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t('cip.dc.datasource.field.sjyxx') }}
          </i>
        </template>

          <el-form  ref="form" :model="form" label-width="100px" disabled>
            <el-row>
                <el-col :span="8">
                 <el-form-item :label="$t('cip.dc.datasource.field.status')" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.id"
              :label="dict.itemText"
            >{{ dict.itemValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
              </el-col>
              <el-col :span="8">
                 <el-form-item :label="$t('cip.dc.datasource.field.datasourceType')" prop="dbType">
          <el-select v-model="form.dbType">
            <el-option
              v-for="item in dbTypeOptions"
              :key="item.id"
              :label="item.itemValue"
              :value="item.itemText"
            />
          </el-select>
        </el-form-item>
              </el-col>
              <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.datasource.field.name')" prop="sourceName">
          <el-input v-model="form.sourceName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.name')" />
        </el-form-item>
              </el-col>

            </el-row>
             <el-row>
              <el-col :span="8">
 <el-form-item :label="$t('cip.dc.offlineDComission.modelState')">
          <el-radio-group v-model="form.modelState">
           <el-radio :label="1">{{ $t('cip.dc.offlineDComission.is') }} &nbsp;&nbsp;</el-radio>
    <el-radio :label="0">{{ $t('cip.dc.offlineDComission.no') }}</el-radio>
          </el-radio-group>


        </el-form-item>
              </el-col>
              <!-- <el-col :span="16">
                 <el-form-item :label="$t('cip.dc.datasource.field.remark')" prop="remark">
          <el-input v-model="form.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.remark')"  />
        </el-form-item>
              </el-col> -->
            </el-row>


             <el-row>
<el-col :span="24">
              <el-form-item :label="$t('cip.dc.datasource.field.remark')" prop="remark">
          <el-input v-model="form.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.remark')"  />
        </el-form-item>
             </el-col>
          </el-row>





      </el-form>




      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t('cip.dc.datasource.field.ljxx') }}
          </i>
        </template>

          <el-form  ref="form2" :model="form2" label-width="80px" disabled>
             <el-row>

    <el-col :span="8">
       <el-form-item :label="$t('cip.dc.datasource.field.host')" prop="host">
          <el-input v-model="form2.host" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.host')" />
        </el-form-item>
    </el-col>
    <el-col :span="8">
       <el-form-item :label="$t('cip.dc.datasource.field.port')" prop="port">
          <el-input v-model="form2.port" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.port')" />
        </el-form-item>
    </el-col>
    <el-col :span="8">
 <el-form-item v-if="form.dbType === '3' || form.dbType === '4'" :label="$t('cip.dc.datasource.field.sit')" prop="sid">
          <el-input v-model="form2.sid" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.sid')" />
        </el-form-item>
        <el-form-item v-if="form.dbType !== '3' && form.dbType !== '4'" :label="$t('cip.dc.datasource.field.dbName')" prop="dbName">
          <el-input v-model="form2.dbName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.dbName')" />
        </el-form-item>
    </el-col>
   </el-row>

       <el-row>
        <el-col :span='8'>
           <el-form-item :label="$t('cip.dc.datasource.field.username')" prop="username">
          <el-input v-model="form2.username" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.username')" />
        </el-form-item>
        </el-col>

       </el-row>


        <!-- <el-form-item :label="$t('cip.dc.datasource.field.password')" prop="password">
          <el-input v-model="form2.password" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.password')" />
        </el-form-item> -->
      </el-form>







      </el-collapse-item>








    </el-collapse>




      <!-- <el-button v-if="active == 1" style="margin-top: 12px;" @click="handleNextStep">{{$t('datacenter.btn.next')}}</el-button>
      <el-button v-if="active == 2" style="margin-top: 12px;" @click="handleLastStep">{{$t('datacenter.btn.prev')}}</el-button> -->
    </div>
  </el-card>
</template>

<script>
import { getDataSource,getDataSource2, checkConnection,checkConnection2, sync, word } from '@/api/metadata/datasource'
import { getDicts } from '@/api/dict'
import HeadLayout from "@/views/components/layout/head-layout";

export default {
   components: {
    HeadLayout,
  },
  name: 'DataSourceDetail',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {

    return {
           activeName:["1","2"],

       headBtnOptions: [
        {
          label: this.$t('cip.dc.offlineDComission.ysj'),
          emit: "head-ysj",
          type: "button",
          icon: "",
          loading: false,
          disabled:true
        },
         {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-back",
          type: "button",
          icon: "",
          loading: false,
        },
      ],
      title:this.$t('cip.dc.datasource.field.sjyxq'),
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showAdd: false,
        showEdit: false,
        showDetail: false
      },
      active: 1,
      // 表单参数
      form: {},
      form2: {},
      // 状态数据字典
      statusOptions: [],
      // 数据源类型数据字典
      // dbTypeOptions: []
      dbTypeOptions: []
    }
  },
  created() {
    console.log('id:' + this.data.id)
    getDicts('sys_common_status').then(res => {
      let response = res.data;
      if (response.success) {
        this.statusOptions = response.data
      }
    })
    getDicts('data_db_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.dbTypeOptions = response.data
      }
    })
  },
  mounted() {
    this.getDataSource2(this.$route.query.id)
  },
  methods: {
    showCard() {
      // this.$emit('showCard', this.showOptions)
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    /** 获取详情 */
    getDataSource2: function(id) {
      if(id){
        getDataSource2(id).then(res => {
        let response = res.data;
        if (response.success) {
          this.form = response.data
          if(this.form.isSync==0){
            this.headBtnOptions[0].disabled = false
          }
          this.form.modelState = parseInt(this.form.modelState);
          if(!this.form.modelState){
            this.form.modelState = 0
          }
          this.form2 = this.form.dbSchema
        }
      })
      }

    },
    /** 步骤条下一步 */
    handleNextStep() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.active++
        }
      })
    },
    /** 步骤条上一步 */
    handleLastStep() {
      this.active--
    },
    /** 检测数据库连通性 */
    handleCheckConnection() {
      // checkConnection(this.form).then(res => {
      //   let response = res.data;
      //   if (response.success) {
      //     this.$message.success(this.$t('datacenter.tips.connectionSuccess'))
      //   }
      // })

      checkConnection2(this.form).then(res => {
        let response = res.data;
        if (response.success) {
          this.$message.success(this.$t('datacenter.tips.connectionSuccess'))
        }
      })
    },
    /** 元数据同步 */
    handleSync() {
      console.log(this.data,"sssssssssss");
      sync(this.$route.query.id).then(res => {
        let response = res.data;
        if (response.success) {
          this.$set(this.form, 'isSync', '1')
          this.$message.success(this.$t('cip.dc.datasource.msg.ysjtbTip'))
        }
      })
    },
    /** 数据库文档 */
    handleWord() {

      word(this.$route.query.id).then(res => {
        let response = res.data;
        const blob = new Blob([response])
        const fileName = this.$t('cip.dc.datasource.field.fileName')
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    }
  }
}
</script>
